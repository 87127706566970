import { useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { ImageNotSupported, Refresh } from '@mui/icons-material';
import { format } from 'date-fns';
import { isEmpty, isNil } from 'lodash';

import { prefetchImage } from '@inspiren-monorepo/shared-react';
import { FallRiskLevel } from '@inspiren-monorepo/shared-types';

import { ImageEventContainer } from './components/ImageEventContainer';

import { MarkData } from '../../../../types';
import theme from '../../../styles/theme';
import {
  doesRangeExceedLimitForImages,
  isPastImageExpiration,
} from '../../../utility/helpers/time';
import { useRoomFallRiskChanges } from '../../Admin/hooks/useRoomFallRiskChanges';
import FallRiskPill from '../../shared/FallRiskPill';
import { useTimestamp } from '../hooks/useTimestamp';
import { useEventReviewStore } from '../store/EventReviewStore';

type Props = {
  src: string | null;
  refresh?: () => void;
  onClick?: () => void;
  selectedRoomId: string;
  marks: MarkData[];
};

const EventImage = ({
  src,
  refresh,
  onClick,
  selectedRoomId,
  marks,
}: Props) => {
  const [loadError, setLoadError] = useState(false);

  const [lastSuccessfulImg, setlastSuccessfulImg] = useState<string | null>(
    null,
  );

  const timestamp = useTimestamp();
  const { events } = useRoomFallRiskChanges(selectedRoomId);

  const fallRisk = useMemo(() => {
    if (!events || isEmpty(events) || isNil(timestamp)) return null;
    const time = timestamp.getTime();

    const filteredEvents = events?.filter(
      (event) => new Date(event.time).getTime() <= time,
    );

    if (isEmpty(filteredEvents)) return null;

    filteredEvents.sort(
      (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime(),
    );

    return filteredEvents[0].fallRiskLevel as FallRiskLevel;
  }, [events, timestamp]);

  const {
    selectedUnit,
    selectedRoom,
    startDate,
    endDate,
    images,
    imagesLoading,
    imagesError,
    lastImagesLoading,
    position,
  } = useEventReviewStore();

  // in the array of marks check to see if current position is within any 'Augi Disabled' marks
  const augiDisabled = useMemo(() => {
    if (isEmpty(marks) || isNil(position)) return false;

    // for each mark check if current position is within mark.value + span if it is, check if the mark.label includes a string 'Augi Disabled'
    return marks.some(
      (mark) =>
        position >= mark.value &&
        position <= mark.value + (mark.span || 0) &&
        mark.label.includes('AUGi Disabled'),
    );
  }, [marks, position]);

  // do the same as above for 'Offline Event' marks
  const offlineEvent = useMemo(() => {
    if (isEmpty(marks) || isNil(position)) return false;

    return marks.some(
      (mark) =>
        position >= mark.value &&
        position <= mark.value + (mark.span || 0) &&
        mark.label.includes('Offline Event'),
    );
  }, [marks, position]);

  // do the same as above for 'Virtual Curtain Enabled' marks
  const virtualCurtainEnabled = useMemo(() => {
    if (isEmpty(marks) || isNil(position)) return false;

    return marks.some(
      (mark) =>
        position >= mark.value &&
        position <= mark.value + (mark.span || 0) &&
        mark.label.includes('Virtual Curtain Enabled'),
    );
  }, [marks, position]);

  // if we change the selection, we want to reset the image
  useEffect(() => {
    setlastSuccessfulImg(null);
  }, [selectedRoom]);

  useEffect(() => {
    if (src) prefetchImage(src, () => setlastSuccessfulImg(src));
  }, [src]);

  useEffect(() => {
    setLoadError(false);
  }, [src]);

  const timeString = timestamp
    ? format(timestamp, 'MM/dd/yyyy h:mm:ss aa')
    : '';

  const alt = `Room${selectedRoom ? ` ${selectedRoom.name}` : ''}${
    timeString ? ` at ${timeString}` : ''
  }`;

  const message = useMemo(() => {
    if (imagesError || loadError) return 'Error loading image';

    if (isPastImageExpiration(endDate, selectedUnit))
      return `Images only available for the past ${selectedUnit?.imageExp} ${
        selectedUnit?.imageExp && selectedUnit.imageExp > 1 ? 'days' : 'day'
      }`;

    if (doesRangeExceedLimitForImages(startDate, endDate))
      return 'Select a range of one day or less to view images';

    if (isEmpty(images)) return 'No images available for time range';

    return null;
  }, [imagesError, loadError, startDate, endDate, selectedUnit, images]);

  if (!src || loadError)
    return (
      <Box
        sx={{
          bgcolor: 'grey.200',
          color: 'grey.500',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        {!src && (imagesLoading || lastImagesLoading) && (
          <CircularProgress size={60} color='inherit' />
        )}

        {(imagesError || loadError) && (
          <>
            <ImageNotSupported fontSize='large' />
            {message && (
              <Typography variant='body2' mt={1} sx={{ userSelect: 'none' }}>
                {message}
              </Typography>
            )}
            {refresh && imagesError && (
              <Button
                startIcon={<Refresh />}
                onClick={() => {
                  setLoadError(false);
                  refresh();
                }}
              >
                Refresh
              </Button>
            )}
          </>
        )}
      </Box>
    );

  if (augiDisabled) return <ImageEventContainer title='AUGi Disabled' />;
  if (offlineEvent) return <ImageEventContainer title='AUGi Offline' />;
  if (virtualCurtainEnabled)
    return <ImageEventContainer title='Virtual Curtain Enabled' />;

  return (
    <>
      <img
        src={lastSuccessfulImg || src}
        alt={alt}
        width='100%'
        height='100%'
        onError={() => setLoadError(true)}
        onClick={onClick}
        style={{ cursor: 'pointer', backgroundColor: theme.palette.grey[200] }}
      />
      <FallRiskPill
        fallRisk={fallRisk}
        size='small'
        sx={{ position: 'absolute', top: 10, right: 32, zIndex: 1 }}
      />
    </>
  );
};

export default EventImage;
