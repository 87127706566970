import { useMemo, useRef } from 'react';
import {
  Box,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { ImageNotSupported } from '@mui/icons-material';

import useFormattedTimeDifference from './hooks/useFormattedTimeDifference';

import { useIsAdmin } from '../../hooks/useIsAdmin';
import { defaultAspectRatio } from '../../rules';
import { useZoom } from '../../utility/hooks';

interface Props {
  onClick?: () => void;
  src?: string;
  width?: number | string;
  height?: number | string;
  enableZoom?: boolean;
  sx?: SxProps<Theme>;
  privacy?: boolean;
  privEndsAt?: string;
  disabled?: boolean;
  loading?: boolean;
  offline?: boolean;
  testID?: string;
  keepAlive?: string;
}

const RoomImage = ({
  onClick,
  src,
  width = defaultAspectRatio.width,
  height = defaultAspectRatio.height,
  enableZoom = false,
  sx = {},
  privacy = false,
  privEndsAt,
  disabled = false,
  loading = false,
  offline = false,
  testID,
  keepAlive,
}: Props) => {
  const zoomHTMLElement = useRef<null | HTMLImageElement>(null);
  useZoom(enableZoom, zoomHTMLElement);

  const statusBoxSxProps = useMemo(
    () => ({
      width,
      height,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
    }),
    [width, height],
  );

  const formattedOfflineTime = useFormattedTimeDifference(
    keepAlive || '',
    offline,
    true,
  );

  const formattedPrivTime = useFormattedTimeDifference(
    privEndsAt || '',
    privacy,
    false,
  );

  const { isAdmin } = useIsAdmin();
  return (
    <Box sx={{ ...sx, width, height }} onClick={onClick} data-testid={testID}>
      {disabled && (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.disableAugi.main,
            color: theme.palette.disableAugi.contrastText,
            ...statusBoxSxProps,
          })}
        >
          AUGi Disabled
        </Box>
      )}

      {(offline || privacy) && (
        <Box
          sx={(theme) => ({
            ...(privacy
              ? {
                  background:
                    'linear-gradient(180deg, rgba(71,109,195,1) 0%, rgba(172,193,253,1) 100%)',
                  color: theme.palette.disableAugi.contrastText,
                }
              : {
                  bgcolor: 'grey.400',
                  color: 'grey.600',
                }),
            ...statusBoxSxProps,
          })}
        >
          {privacy ? (
            <Stack direction='column' alignItems='center'>
              <Typography sx={{ userSelect: 'none', fontSize: '1.5em' }}>
                Virtual Curtain
              </Typography>
              <Typography sx={{ userSelect: 'none', fontSize: '1.5em' }}>
                {formattedPrivTime}
              </Typography>
            </Stack>
          ) : (
            <>
              <ImageNotSupported fontSize='large' />
              <Stack direction='column' alignItems='center'>
                <Typography sx={{ userSelect: 'none' }}>Offline</Typography>
                {isAdmin && (
                  <Typography sx={{ userSelect: 'none' }}>
                    {formattedOfflineTime && `for ${formattedOfflineTime}`}
                  </Typography>
                )}
              </Stack>
            </>
          )}
        </Box>
      )}

      {loading && (
        <Skeleton variant='rectangular' width={width} height={height} />
      )}

      <img
        src={src}
        ref={zoomHTMLElement}
        width={width}
        height={height}
        alt=''
      />
    </Box>
  );
};

export default RoomImage;
