import React, { useCallback, useMemo, useState } from 'react';
import { LinearProgress, SxProps, Theme } from '@mui/material';
import { Check } from '@mui/icons-material';
import {
  DataGrid,
  GridActionsColDef,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridRowIdGetter,
  GridRowParams,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { isString } from 'lodash';

import TableError from './TableError';

import InternalToolbar from '../InternalToolbar';
import EditModal from '../modals/EditModal';
import { OnSubmitFormModal, RenderFormModal } from '../modals/FormModalBase';
import { DataFields } from '../types/DataFields';

interface Props<FieldTypes> {
  itemName: string;
  sx?: SxProps<Theme>;
  fields: DataFields<FieldTypes>;
  data?: GridValidRowModel[];
  loading?: boolean;
  modalLoading?: boolean;
  error?: string | null | boolean;
  modalError?: string | null | boolean;
  onModalOpen?: () => void;
  onEditSubmit?: OnSubmitFormModal<FieldTypes>;
  onAddSubmit: OnSubmitFormModal<FieldTypes>;
  getRowId?: GridRowIdGetter;
  disableEditing?: boolean;
  defaultSort?: string;
  paginationMode?: 'server' | 'client';
  rowCount?: number;
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange?: (page: number) => void;
  page?: number;
  pageSize?: number;
  filterMode?: 'server' | 'client';
  onFilterModelChange?: (model: GridFilterModel) => void;
  sortingMode?: 'server' | 'client';
  onSortModelChange?: (model: GridSortModel) => void;
  addExport?: boolean;
  customNoRowsText?: string;
  tableHeader?: React.ReactNode;
  extraToolbarButtons?: React.ReactNode;
  showAddButton?: boolean;
  disableAddButton?: boolean;
  renderModal?: RenderFormModal<FieldTypes>;
}

const TableBase = <FieldTypes,>({
  itemName,
  sx,
  fields,
  data = [],
  loading = false,
  modalLoading = false,
  error,
  modalError = null,
  onModalOpen,
  onEditSubmit,
  onAddSubmit,
  getRowId,
  disableEditing = false,
  defaultSort,
  paginationMode = 'client',
  rowCount,
  onPageChange,
  onPageSizeChange,
  pageSize,
  page,
  filterMode = 'client',
  onFilterModelChange,
  addExport,
  sortingMode = 'client',
  onSortModelChange,
  customNoRowsText,
  tableHeader,
  extraToolbarButtons,
  showAddButton,
  disableAddButton,
  renderModal,
}: Props<FieldTypes>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  const getActions = useCallback(
    (params: GridRowParams) =>
      disableEditing
        ? []
        : [
            <EditModal<FieldTypes>
              itemName={itemName}
              fields={fields}
              initialValues={params.row}
              loading={modalLoading}
              error={modalError}
              onSubmit={onEditSubmit}
              onOpen={onModalOpen}
              renderModal={renderModal}
            />,
          ],
    [
      disableEditing,
      itemName,
      fields,
      modalLoading,
      modalError,
      onEditSubmit,
      onModalOpen,
    ],
  );

  const editColumn: GridActionsColDef = useMemo(
    () => ({
      field: 'actions',
      type: 'actions',
      width: 50,
      hideable: false,
      getActions,
    }),
    [getActions],
  );

  const dataColumns: GridColDef[] = useMemo(
    () =>
      fields
        .filter(({ width }) => width !== 'hidden')
        .map(
          ({
            field,
            label,
            width,
            flex,
            valueFormatter,
            cellClassName,
            renderCell: renderFn,
            editType,
          }) => {
            let renderCell;

            if (renderFn) {
              renderCell = renderFn;
            } else if (editType === 'boolean') {
              // Display checkmark for `true` and nothing for other values
              renderCell = (params: GridRenderCellParams) => {
                if (params.value === true) return <Check sx={{ mx: 'auto' }} />;
                return '';
              };
            }

            return {
              field,
              headerName: label,
              width: width as number,
              flex,
              valueFormatter,
              cellClassName,
              renderCell,
            };
          },
        ),
    [fields],
  );

  const columns = useMemo(
    () => [editColumn, ...dataColumns],
    [editColumn, dataColumns],
  );

  const errorMessage = useMemo(() => {
    if (error === true) return '';
    if (isString(error)) return error;
    return null;
  }, [error]);

  const modalErrorMessage = useMemo(() => {
    if (modalError === true) return '';
    if (isString(modalError)) return modalError;
    return null;
  }, [modalError]);

  return (
    <DataGrid
      error={errorMessage}
      loading={loading}
      sx={sx}
      autoHeight
      rowsPerPageOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          pageSize: 25,
        },
        sorting: {
          sortModel: [{ field: defaultSort || 'id', sort: 'asc' }],
        },
      }}
      disableSelectionOnClick
      getRowId={getRowId}
      columns={columns}
      rows={data}
      components={{
        Toolbar: InternalToolbar<FieldTypes>,
        LoadingOverlay: LinearProgress,
        ErrorOverlay: TableError,
      }}
      paginationMode={paginationMode}
      componentsProps={{
        panel: {
          anchorEl,
          placement: 'bottom-end',
        },
        toolbar: {
          itemName,
          fields,
          tableHeader,
          setAnchorEl,
          onAddSubmit,
          onAddOpen: onModalOpen,
          addLoading: modalLoading,
          addError: modalErrorMessage,
          addExport,
          extraButtons: extraToolbarButtons,
          disableAddButton,
          showAddButton,
          renderModal,
        },
        errorOverlay: {
          error: errorMessage,
        },
      }}
      rowCount={rowCount}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      pageSize={pageSize}
      page={page}
      filterMode={filterMode}
      filterModel={filterModel}
      onFilterModelChange={(model) => {
        setFilterModel(model);
        onFilterModelChange?.(model);
      }}
      sortingMode={sortingMode}
      onSortModelChange={onSortModelChange}
      localeText={{
        noRowsLabel: customNoRowsText || 'No rows',
      }}
    />
  );
};

export default TableBase;
